<template>
    <div class="page-body wrapper-fixed">
        <div class="login-control d-flex">
            <a href="javascript:history.go(-1)" class="back-button text-capitalize" v-if="showBackButton">
                <div class="back-button__icon">
                    <i class="eicon e-back"></i>
                </div>
                <div class="back-button__text">{{ $t("back")}}</div>
            </a>
            <div class="ml-auto d-flex">
                <language-switcher></language-switcher>
                <router-link :to="{name: 'logout'}" class="back-button ml-4 text-capitalize" v-if="!hasCompany">
                    <div class="back-button__icon">
                        <i class="eicon e-logout"></i>
                    </div>
                    <div class="back-button__text">{{ $t("logout")}}</div>
                </router-link>
            </div>
        </div>
        <div class="col-md-6 login-thumbnail">
            <a href="#" class="site-logo">
                <site-logo-s-v-g/>
            </a>
            <img src="/img/Repeat Grid 3.png" alt="" class="login-page__background"/>
        </div>
        <div class="col-md-6 login-content">
            <div class="login-box mt-4">
                <h2 class="login-box__title text-capitalize">{{ $t("Give us few more information about your company")}}</h2>
                <form>
                    <div class="form-group">
                        <label for="company-name">{{ $t("Company Name")}}*</label>
                        <input id="company-name" class="form-control" maxlength="50" type="text" :placeholder="$t('your company name')" v-model="form.name"/>
                        <div class="error-message" v-if="errors.name">{{ $t(errors.name) }}</div>

                    </div>
                    <div class="form-group">
                        <label for="username">{{ $t("username or company")}}*</label>
                        <div class="input-group">
                            <input id="username" class="form-control" maxlength="50" type="text" :placeholder="$t('your company user name')" v-model="form.username"/>
                            <div class="input-group-append">
                                <span class="input-group-text color-primary background-light">.easy.jobs</span>
                            </div>
                        </div>
                        <div class="form-note mt-2">
                        <span class="text-capitalize">{{ $t("tips")}}: </span>{{ $t("Accepted characters for username are alphabets, numbers, dot, hyphen & underscore.")}}
                        </div>
                        <div class="error-message" v-if="errors.username">{{ $t(errors.username) }}</div>
                    </div>
                    <div class="form-group">
                        <label for="phone-no">{{ $t("Phone No")}}*</label>
                        <input id ="phone-no" class="form-control" type="text" placeholder="0123456789" v-model="form.mobile_number" maxlength="20"/>
                        <div class="error-message" v-if="errors.mobile_number">{{ $t(errors.mobile_number) }}</div>
                    </div>
                    <div class="form-group">
                        <label for="industry">{{ $t("Industry")}}*</label>
                        <multiselect
                                id="industry"
                                :options="industries"
                                @open="getCompanyTypes"
                                :placeholder="$t('Select')"
                                track-by="id"
                                label="name"
                                v-model="form.industry"
                        ></multiselect>
                        <div class="error-message" v-if="errors.industry">{{ $t(errors.industry) }}</div>
                    </div>

                    <div class="form-group">
                        <label for="website">{{$t(`Website URL`)}}*</label>
                        <input id="website" type="text" v-model="form.website" class="form-control" :placeholder="$t(`Enter Website URL`)"/>
                        <div class="form-note mt-2">
                        <span class="text-capitalize">{{ $t("tips")}}: </span>{{ $t("Website URL should look like")}} http://www.example.com
                        </div>
                        <div class="error-message" v-if="errors.website">{{ $t(errors.website) }}</div>
                    </div>

                    <div class="row employeeNumber">
                        <label>{{ $t("Number of Employees")}}*</label>
                        <div class="col-md-3">
                            <div class="employeeNumber__counter">
                                <input type="radio" name="employee_count" id="employee-count1" value="1"
                                       v-model="form.company_size"/>
                                <label class="employeeNumber__info" for="employee-count1">
                                    <h4 class="employeeNumber__total">1-30</h4>
                                    <span class="employeeNumber__title">{{ $t("Employees")}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="employeeNumber__counter">
                                <input type="radio" name="employee_count" id="employee-count2" value="2"
                                       v-model="form.company_size"/>
                                <label class="employeeNumber__info" for="employee-count2">
                                    <h4 class="employeeNumber__total">31-60</h4>
                                    <span class="employeeNumber__title">{{ $t("Employees")}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="employeeNumber__counter">
                                <input type="radio" name="employee_count" id="employee-count3" value="3"
                                       v-model="form.company_size"/>
                                <label class="employeeNumber__info" for="employee-count3">
                                    <h4 class="employeeNumber__total">61-90</h4>
                                    <span class="employeeNumber__title">{{ $t("Employees")}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="employeeNumber__counter">
                                <input type="radio" name="employee_count" id="employee-count4" value="4" v-model="form.company_size"/>
                                <label class="employeeNumber__info" for="employee-count4">
                                    <h4 class="employeeNumber__total">100+</h4>
                                    <span class="employeeNumber__title">{{ $t("Employees")}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="error-message" v-if="errors.company_size">{{ $t(errors.company_size) }}</div>
                    </div>
                    <label class="checkbox mt-3">
                        <input id="terms_and_policy" type="checkbox" v-model="form.terms_and_policy"/>
                        <span></span>
                        <div class="error-message" v-if="errors.terms_and_policy">{{ $t(errors.terms_and_policy) }}</div>

                    </label>
                    <label for="terms_and_policy">{{ $t("i agree with to the ")}} <a target="_blank" href="https://easy.jobs/terms">{{ $t("terms and policy")}}</a>*</label>

                    <div class="d-flex justify-content-between equal-divided mt-3">
                        <submit-button :click="submitNewCompany" :loading="isLoading" :disabled="!form.terms_and_policy">{{ $t("Get Started")}}</submit-button>
<!--                        <a href="javascript:history.go(-1)" class="button semi-button-info">{{ $t("skip this step now")}}</a>-->
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<style>
    .login-control .language-control {
        margin-left: auto;
    }
    .login-box .equal-divided * {
        width: auto;
    }
    .login-box .equal-divided > * {
        width: calc(50% - 10px);
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import client from "../../app/api/Client";
    import Multiselect from "vue-multiselect";
    import {getCompanyTypes as loadCompanyTypes} from "../../app/api/CommonRequest";
    import {SWITCHED_TO_ANOTHER_ACCOUNT} from '../../constants/events';
    import {CREATED_A_NEW_COMPANY, USER_LOGGED_IN} from '../../constants/action-type';
    import {mapActions, mapState} from 'vuex';
    import {EventBus} from '../../extra/event-bus';
    import {USER_TYPE} from "../../constants/enums";

    const LanguageSwitcher = () => import( "../../components/dropdown/LanguageSwitcher");
    const ErrorMessage = () => import( "../../components/common/ErrorMessage");
    const SiteLogoSVG = () => import( "../../components/SiteLogoSVG");

    export default {
        components: {
            SiteLogoSVG,
            ErrorMessage,
            LanguageSwitcher,
            Multiselect,
        },
        data() {
            return {
                form: {
                    industry: null,
                    name: '',
                    username: '',
                    mobile_number: '',
                    website: '',
                    company_size: 1,
                    terms_and_policy: false,
                    time_zone: 'UTC'
                },
                errors: {},
                industries: [],
                isLoading: false
            }
        },
        computed: {
            ...mapState(['user']),
            hasCompany() {
                return this.user.companies.length > 0 ;
            },

            showBackButton(){
                return this.user.type === USER_TYPE.CANDIDATE || this.hasCompany;
            }
        },

        methods: {
            ...mapActions([CREATED_A_NEW_COMPANY, USER_LOGGED_IN]),
            isNumber() {
                if(this.form.mobile_number.length == 20) {
                    event.preventDefault();
                }
                if ( event.keyCode === 101 || event.keyCode === 46) {
                    event.preventDefault();
                }
               return true;
            },
            getCompanyTypes() {
                loadCompanyTypes().then(({data}) => {
                    this.industries = data;
                }).catch(error => {
                    return this.showErrorText(error)
                });
            },

            submitNewCompany() {
                this.isLoading = true;
                this.form.time_zone= Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (!this.isValid()) {
                    this.isLoading = false;
                    this.$toast.error(this.$t('Oops! Please check your input'));
                    return false;
                }

                client()
                    .post('/company', this.form)
                    .then(({data: {data, message}}) => {

                        if ((data).hasOwnProperty('user') && (data).hasOwnProperty('company')) {
                            this[USER_LOGGED_IN](data.user);
                            this[CREATED_A_NEW_COMPANY](data.company);
                            EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, 'company');
                        }
                        this.$toast.success(this.$t(message));
                        this.isLoading = false;
                        this.$router.push({name: 'dashboard'});

                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.showErrorText(error)
                    });
            },
            isValid() {
                this.errors = {};
                if(this.form.name.length === 0) {
                    this.errors.name = 'please provide a valid company name';
                    return false;
                }
                if(this.form.name.length > 50) {
                    this.errors.name = 'name must be less then 50';
                    return false;
                }

                if(this.form.username.length === 0) {
                    this.errors.username = 'please provide a valid username';
                    return false;
                }

                if(this.form.username.length > 50) {
                    this.errors.username = 'username must be less then 50';
                    return false;
                }

                if(this.form.mobile_number.length === 0) {
                    this.errors.mobile_number = 'please provide a valid phone no';
                    return false;
                }
                if(this.form.mobile_number.length > 20) {
                    this.errors.mobile_number = 'phone no must be less then 20';
                    return false;
                }

                if(this.form.website.length === 0) {
                    this.errors.website = 'please provide a valid website';
                    return false;
                }
                if(this.form.website.length > 100) {
                    this.errors.website = 'Website URL must be less then 100.';
                    return false;
                }

                if(this.form.terms_and_policy === false) {
                    this.errors.terms_and_policy = 'please agree to terms and condition';
                    return false;
                }

                if(this.form.industry === null) {
                    this.errors.industry = 'please select an industry';
                    return false;
                }

                return true;
            },
            showErrorText(error) {

                if (typeof error == 'string') {
                    return this.$toast.error(error);
                } else if (error.response !== undefined && error.response.status === 422) {/* validation error */

                    if (typeof error.response.data.message == 'string') {

                        return this.$toast.error(error.response.data.message);/* System error */
                    }
                    this.errors = _helper.serializeValidationMessage(error.response.data.message);
                    return this.$toast.error( this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(error.response.data.message);
                }
            }
        },
    }


</script>
<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }


</style>

<style>
.multiselect__tags {
    padding-top: 0;
    padding-left: 26px;
    min-height: 48px;
    border-color: #cdd8fe;
    color: #000;
    background: #f5f7fd;
}

input.multiselect__input {
    min-height: 46px;
    line-height: 46px;
    margin-bottom: 0;
    background: #f5f7fd;
    font-size: 0.875rem;
    color: #1c2238;
}

span.multiselect__placeholder {
    margin: 0;
    padding: 0;
    color: #1c2238;
    font-size: 0.875rem;
    font-weight: 500;
}

.multiselect__select {
    height: 48px;
}

span.multiselect__option {
    padding: 12px 26px;
}

span.multiselect__single {
    padding: 0;
    margin: 0;
    height: 48px;
    line-height: 48px;
    color: #1b2237;
    background: #f5f7fd;
}

span.multiselect__placeholder {
    height: 48px;
    line-height: 48px;
}

input.multiselect__input {
    padding: 0;
}

.multiselect__spinner {
    height: 48px;
    background: #f5f7fd;
    border-radius: 5px;
}

.multiselect.disabled {
    pointer-events: none;
}

.multiselect.disabled:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #d8cece63;
    top: 0;
    left: 0;
    border-radius: 5px;
}

span.multiselect__tag {
    margin-top: 12px;
}

</style>